import { router } from "@inertiajs/react";
import React, { useEffect, useState } from "react";
import { BiSearchAlt } from "react-icons/bi";

import SimpleButton from "@/components/Buttons/SimpleButton";
import { BigNumber } from "@/components/Cards/BigNumber";
import FaqCardComponent from "@/components/Cards/FaqComponent";
import PersonCard from "@/components/Cards/PersonCard";
import Dropdown from "@/components/Dropdown";
import Layout from "@/components/Layout";
import Typography from "@/components/Typography";
import {
  ButtonContainer,
  FiltersContainer,
  HeaderInfo,
  ListSection,
  PageContainer,
} from "@/constants/listIndexStyle";

import { SearchForm } from "../power-plants/style";

const statusOptions: string[] = ["Status", "Distribuição regular", "Distribuição pausada"];
const situationOptions: string[] = ["Situação", "Contas em dia", "Inadimplente"];

const ConsumersPage = ({
  consumersInfo,
  consumers,
  currentUser,
  powerPlantsOptions = [
    {
      id: 0,
      name: "Usina",
    },
  ],
  conglomeratesOptions = [
    {
      id: 0,
      name: "Conglomerado",
    },
  ],
}: any) => {
  const [filteredConsumers, setFilteredConsumers] = useState<any[]>([]);
  const [filters, setFilters] = useState({
    situation: "Situação",
    status: "",
    powerPlantName: "",
    conglomerateName: "",
    search: "",
  });
  const [page, setPage] = useState<number>(1);
  const totalPages = 1;

  const changeFilters = ({
    situation = filters.situation,
    status = filters.status,
    powerPlantName = filters.powerPlantName,
    conglomerateName = filters.conglomerateName,
    search = filters.search,
  }) => {
    setPage(1);
    setFilters({ situation, status, powerPlantName, conglomerateName, search });
  };
  const showMore = () => page != totalPages && filteredConsumers.length != 0;

  // Refresh Consumers
  useEffect(() => {
    setFilteredConsumers(consumers);
  }, [consumers]);

  // Filters

  const statusValues = {
    Status: null,
    "Distribuição regular": "regular",
    "Distribuição pausada": "stopped",
  };
  const situationValues = { Situação: null, "Contas em dia": "paid", Inadimplente: "late" };

  useEffect(() => {
    const options = {
      overdue_status:
        situationValues[filters?.situation] !== undefined
          ? situationValues[filters.situation]
          : filters.situation,
      distribution_status:
        statusValues[filters?.status] !== undefined ? statusValues[filters.status] : filters.status,
      power_plant_id: powerPlantsOptions.find((pw) => pw.name == filters.powerPlantName)?.id,
      conglomerate_id: conglomeratesOptions.find((cg) => cg.name == filters.conglomerateName)?.id,
      page: page,
      name: filters.search,
    };
    router.reload({ only: ["consumers"], data: options });
  }, [currentUser, filters, totalPages, page, powerPlantsOptions]);

  return (
    <Layout>
      <PageContainer>
        <Typography variant="h3">Clientes</Typography>
        {consumersInfo && (
          <HeaderInfo>
            <div className="grid w-full grid-cols-1 justify-between gap-4 pt-5 md:grid-cols-4">
              <BigNumber infoTitle="Número de clientes" info={consumersInfo.consumers_count} />
              <BigNumber
                infoTitle="Número de unidades consumidoras"
                info={consumersInfo.facilities_count}
              />
              <BigNumber infoTitle="inadimplentes" info={consumersInfo.late_consumers_count} />
              <BigNumber
                infoTitle="distribuição pausada"
                info={consumersInfo.stopped_distribution_count}
              />
            </div>
          </HeaderInfo>
        )}

        <ListSection className="flex-col md:flex-row">
          <Typography variant="h4">Listagem</Typography>
          <FiltersContainer className="pt-5 md:pt-0">
            <SearchForm className="search" onSubmit={(e) => e.preventDefault()}>
              <input
                type="text"
                value={filters.search}
                onChange={(e) => setFilters({ ...filters, search: e.target.value })}
              />
              <button className="search-icon">
                <BiSearchAlt />
              </button>
            </SearchForm>
            {conglomeratesOptions !== null && (
              <Dropdown
                options={conglomeratesOptions.map((cg) => cg.name)}
                onSelect={(value) => {
                  changeFilters({ conglomerateName: value });
                }}
              />
            )}
            {powerPlantsOptions !== null && (
              <Dropdown
                options={powerPlantsOptions.map((pw) => pw.name)}
                onSelect={(value) => {
                  changeFilters({ powerPlantName: value });
                }}
              />
            )}
            <Dropdown
              options={situationOptions}
              onSelect={(value) => {
                changeFilters({ situation: value });
              }}
            />
            <Dropdown
              options={statusOptions}
              onSelect={(value) => {
                changeFilters({ status: value });
              }}
            />
          </FiltersContainer>
        </ListSection>

        <HeaderInfo>
          {filteredConsumers.length > 0 ? (
            <>
              {filteredConsumers.map((consumer, id) => (
                <PersonCard
                  key={id}
                  situation={consumer.overdue_status}
                  name={consumer.name}
                  nickname={consumer.nickname}
                  status={consumer.distribution_status}
                  powerPlant={consumer.effective_power_plants.join(", ")}
                  onClick={() => {
                    router.visit(`/manager/internal-consumer/${consumer.user_id}`);
                  }}
                />
              ))}
            </>
          ) : (
            <Typography variant="h6">Não houve resultado para sua filtragem</Typography>
          )}
        </HeaderInfo>

        {showMore() && (
          <ButtonContainer>
            <SimpleButton
              variant="simple"
              label=" + Carregar mais"
              onClick={() => setPage(page + 1)}
            />
          </ButtonContainer>
        )}

        <div className="pt-36">
          <FaqCardComponent variant="questionSection" />
        </div>
      </PageContainer>
    </Layout>
  );
};

export default ConsumersPage;
