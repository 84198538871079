import { usePage } from "@inertiajs/react";

import Avatar from "../Avatar";
import Typography from "../Typography";
import { TabNavBarProps } from "./types";

const TabNavBar = ({ tabs, showIndex = 0, setShowIndex }: TabNavBarProps) => {
  const { currentUser }: any = usePage().props;

  return (
    <div className="fixed top-6 z-10 flex h-[72px] w-[1280px] items-center justify-between rounded-16 bg-white py-3 pl-10 pr-4 shadow-3xl">
      <div className="w-fit rounded-16 bg-grey100 px-4 py-2 ">
        <Typography variant="h6" color="grey300">
          LOGO
        </Typography>
      </div>
      <div>
        <div className="flex w-full gap-2">
          {tabs.map((tab, index) => {
            if (index == showIndex) {
              return (
                <div
                  key={index}
                  className="flex w-fit cursor-pointer items-center gap-2 rounded-full bg-primary500 py-1 pl-4 pr-6"
                >
                  {<tab.icon className="text-white" />}
                  <Typography variant="btnLarge" color="white">
                    {tab.label}
                  </Typography>
                </div>
              );
            } else {
              return (
                <div
                  key={index}
                  onClick={() => setShowIndex(index)}
                  className="flex w-fit cursor-pointer items-center gap-2 rounded-full py-1 pl-4 pr-6"
                >
                  {<tab.icon className="text-white" />}
                  <Typography variant="btnLarge" color="black">
                    {tab.label}
                  </Typography>
                </div>
              );
            }
          })}
        </div>
      </div>
      <Avatar user={currentUser.name} />
    </div>
  );
};

export default TabNavBar;
