import styled from "styled-components";

import theme from "@/constants/theme";

import { VariantProps } from "./types";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${theme.colors.grey100};
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Infos = styled.div<VariantProps>`
  width: 100%;
  height: auto;
  background-color: ${theme.colors.primary500};
  border-radius: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 184px;
  overflow: hidden;

  .title-position {
    width: 100%;
    height: 48px;
    padding: ${(props) =>
      props.variant != "first-time" ? "0px 0px 88px 128px" : "0px 0px 88px 128px"};
  }

  @media (max-width: 450px) {
    padding-top: 115px;

    .title-position {
      display: flex;
      padding: 0px;
      justify-content: center;
    }
  }
`;

export const FaqCard = styled.div`
  width: calc(100% + 16px);
  min-height: 312px;
  display: flex;
  align-items: center;
  padding: 0px 128px 0px 128px;

  @media (max-width: 450px) {
    justify-content: flex-start;
    align-items: flex-start;
    padding: 32px 8px 0px 8px;
  }
`;

export const FirstTimePosition = styled.div`
  width: 100%;
  height: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;

  .next-bill {
    width: auto;
    height: auto;
    background-color: ${theme.colors.white};
    padding: 8px 24px 8px 24px;
    border-radius: 16px;
    margin-top: 16px;
  }

  .circles-background {
    width: 100%;
    height: auto;
    margin-left: -80px;
    padding-top: 36px;
  }
`;

export const CurrentCountPosition = styled.div<VariantProps>`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .values {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 128px 0px 128px;
  }

  .card-position {
    margin-top: 32px;
    width: calc(100% - 256px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 120px 0px 72px 0px;
  }

  .check-details {
    width: 100%;
    height: 125px;
    background-color: ${theme.colors.primary400};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 128px 0px 128px;
    border-bottom: 1px solid ${theme.colors.grey100};
    margin-bottom: 32px;

    .next-page {
      width: 140px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: white;
      font-size: 18px;
    }
  }

  @media (max-width: 450px) {
    width: 100%;
    align-items: center;
    justify-content: center;

    .values {
      width: 100%;
      flex-direction: column;
      padding: 0px 20px 0px 20px;
    }

    .card-position {
      width: calc(100% - 40px);

      padding: 20px 0px 24px 0px;
    }

    .check-details {
      width: 100%;
      height: 125px;
      background-color: ${theme.colors.primary400};
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 20px 0px 20px;

      .check-details-text-link {
        display: none;
      }

      .next-page {
        height: 100%;
        justify-content: center;
        color: white;
        font-size: 24px;
        padding-left: 70px;
      }
    }
  }
`;
export const Warning = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  .warning-center {
    width: 75%;
    padding-bottom: 48px;
  }
`;
