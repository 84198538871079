import Typography from "../Typography";
import { BadgeProps } from "./types";

const Badge = ({ leftIcon: LeftIcon, text, rightIcon: RightIcon }: BadgeProps) => {
  return (
    <div className="flex h-fit w-fit items-center gap-1 rounded-full bg-success200 py-0.5 pl-2 pr-2.5">
      {LeftIcon && <LeftIcon className="text-xs text-grey950" />}
      <Typography variant="overline" color="grey950" className="whitespace-nowrap">
        {text}
      </Typography>
      {RightIcon && <RightIcon className="text-xs text-grey950" />}
    </div>
  );
};

export default Badge;
