import Footer from "../Footer";
import TabNavBar from "../TabNavBar";
import { TabNavBarProps } from "./types";

const TabNavBarLayout = ({ children, setShowIndex, showIndex, tabs }: TabNavBarProps) => {
  return (
    <div className="flex w-full justify-center">
      <TabNavBar showIndex={showIndex} setShowIndex={setShowIndex} tabs={tabs} />
      <div className="relative mb-80 flex min-h-screen w-[1280px] flex-col gap-14 pt-32">
        {children}
      </div>
      <div className="absolute bottom-0 w-full">
        <Footer />
      </div>
    </div>
  );
};

export default TabNavBarLayout;
