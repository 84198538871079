import { colorType } from "@/constants/types";

export interface TypographyProps {
  variant: VariantType;
  color?: colorType;
  cursorPointer?: boolean;
  className?: string;
}

export const variantsWeight: { [id: string]: string } = {
  big: "bold",
  h1: "bold",
  h2: "bold",
  h3: "bold",
  h4: "bold",
  h5: "bold",
  h6: "bold",
  pLarge: "regular",
  pSmall: "regular",
  caption: "medium",
  overline: "600",
  link: "normal",
  btnLarge: "bold",
  btnMedium: "bold",
  btnSmall: "bold",
};

export type VariantType =
  | "big"
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "pLarge"
  | "pSmall"
  | "caption"
  | "overline"
  | "link"
  | "btnLarge"
  | "btnMedium"
  | "btnSmall";

const proportionalLineHeight = (size: number): string => {
  return `${size}%`;
};

export const variantsSizes: { [id: string]: string } = {
  big: "4rem",
  h1: "3.5rem",
  h2: "3rem",
  h3: "2.5rem",
  h4: "2rem",
  h5: "1.5rem",
  h6: "1.25rem",
  pLarge: "1rem",
  pSmall: "0.875rem",
  caption: "0.75rem",
  overline: "0.75rem",
  link: "1rem",
  btnLarge: "1rem",
  btnMedium: "0.875rem",
  btnSmall: "0.75rem",
};

export const variantsSizesResponsive: { [id: string]: string } = {
  big: "2rem",
  h1: "2.5rem",
  h2: "2rem",
  h3: "1.5rem",
  h4: "1rem",
  h5: "0.5rem",
  h6: "1rem",
  pLarge: "1rem",
  pSmall: "0.875rem",
  caption: "0.75rem",
  overline: "0.75rem",
  link: "1rem",
  btnLarge: "0.75rem",
  btnMedium: "0.875rem",
  btnSmall: "0.75rem",
};

export const variantsLineHeight: { [id: string]: string } = {
  big: proportionalLineHeight(120),
  h1: proportionalLineHeight(120),
  h2: proportionalLineHeight(120),
  h3: proportionalLineHeight(120),
  h4: proportionalLineHeight(120),
  h5: proportionalLineHeight(160),
  h6: proportionalLineHeight(160),
  pLarge: proportionalLineHeight(160),
  pSmall: proportionalLineHeight(160),
  caption: proportionalLineHeight(160),
  overline: proportionalLineHeight(160),
  link: proportionalLineHeight(160),
  btnLarge: proportionalLineHeight(160),
  btnMedium: proportionalLineHeight(170),
  btnSmall: proportionalLineHeight(160),
};
