import React from "react";
import { BiWallet } from "react-icons/bi";
import { BsCalendar2Week } from "react-icons/bs";
import { IoFlashOutline } from "react-icons/io5";
import { TbSunrise } from "react-icons/tb";

import SimpleButton from "@/components/Buttons/SimpleButton";

import ContaInfos from "../ContaInfos";
import { Container } from "./styles";
import { ContaProps } from "./types";

const Conta = ({
  month,
  value,
  kilowatts,
  status,
  buttonLabel,
  onClick,
  powerPlant,
}: ContaProps) => {
  return (
    <Container>
      <div>
        <ContaInfos info={month} title="MÊS" image={<BsCalendar2Week />} />
        <div className="line" />
        <ContaInfos info={value} title="VALOR" image={<BiWallet />} />
        <div className="line" />
        <ContaInfos info={kilowatts} title="KILOWATTS" image={<IoFlashOutline />} />
        <div className="line" />
        <ContaInfos info={powerPlant} title="USINA" image={<TbSunrise />} />
      </div>
      <div>
        <ContaInfos info={status} title="SITUAÇÃO" />
        <div className="line" />
        <SimpleButton variant="simple" onClick={onClick} label={buttonLabel} />
      </div>
    </Container>
  );
};

export default Conta;
