import QuotasCard from "@/components/Cards/Investor/QuotasCard";
import { QuotasCardProps } from "@/components/Cards/Investor/QuotasCard/types";
import Typography from "@/components/Typography";

const QuotasSection = () => {
  // INFORMACOES A SEREM ATUALIZADAS

  const plants: QuotasCardProps[] = [
    {
      plantName: "Usina 1",
      dueNumber: 4,
      positionValue: "0.000.00",
      earningsValue: "0.000.00",
      status: "operating",
    },
    {
      plantName: "Usina 2",
      dueNumber: 4,
      positionValue: "0.000.00",
      earningsValue: "0.000.00",
      status: "building",
    },
  ];

  // ----------

  return (
    <section className="flex h-fit flex-col gap-5">
      <div className="flex h-fit items-center justify-between">
        <Typography variant="h4" color="black">
          Minhas cotas
        </Typography>
      </div>
      {plants.map(({ plantName, dueNumber, positionValue, earningsValue, status }, index) => {
        return (
          <QuotasCard
            key={index}
            plantName={plantName}
            dueNumber={dueNumber}
            positionValue={positionValue}
            earningsValue={earningsValue}
            status={status}
          />
        );
      })}
    </section>
  );
};

export default QuotasSection;
