import { createColumnHelper } from "@tanstack/react-table";

import SimpleButton from "@/components/Buttons/SimpleButton";
import Table from "@/components/Table";
import Typography from "@/components/Typography";

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor("docName", {
    header: () => (
      <Typography variant="overline" color="white" className="uppercase">
        título
      </Typography>
    ),
    cell: (info: any) => (
      <div className="flex w-full justify-start px-6">
        <Typography variant="btnLarge" color="black">
          {info.row.original.docName}
        </Typography>
      </div>
    ),
  }),
  columnHelper.accessor("plant", {
    header: () => (
      <Typography variant="overline" color="white" className="uppercase">
        usina
      </Typography>
    ),
    cell: (info: any) => (
      <div className="flex w-full justify-start px-6">
        <Typography variant="btnLarge" color="black">
          {info.row.original.plant}
        </Typography>
      </div>
    ),
  }),
  columnHelper.accessor("date", {
    header: () => (
      <Typography variant="overline" color="white" className="uppercase">
        data
      </Typography>
    ),
  }),
  columnHelper.accessor("download", {
    header: () => "",
    cell: () => (
      <div className="flex w-full justify-center">
        <div className="w-40">
          <SimpleButton variant="simple" label="Baixar" />
        </div>
      </div>
    ),
  }),
];

const DocsTableSection = () => {
  // INFORMACOES A SEREM ATUALIZADAS

  const tableContent = [
    {
      docName: "Documento 1",
      plant: "Usina 1",
      date: "28/10/2024",
    },
    {
      docName: "Documento 2",
      plant: "Usina 2",
      date: "28/10/2024",
    },
    {
      docName: "Documento 3",
      plant: "Usina 3",
      date: "28/10/2024",
    },
    {
      docName: "Documento 4",
      plant: "Usina 4",
      date: "28/10/2024",
    },
    {
      docName: "Documento 5",
      plant: "Usina 5",
      date: "28/10/2024",
    },
  ];

  // ----------

  return (
    <section className="flex h-fit flex-col gap-5">
      <div className="flex h-fit items-center justify-between">
        <Typography variant="h4" color="black">
          Documentos importantes
        </Typography>
        <div />
      </div>
      <div>
        <Table data={tableContent} columns={columns} onClickRow={null} />
      </div>
    </section>
  );
};

export default DocsTableSection;
