import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React from "react";

const Table = ({ data, columns, onClickRow }) => {
  const [expanded, setExpanded] = React.useState({});

  const table = useReactTable({
    data,
    columns,
    state: {
      expanded,
    },
    getSubRows: (row: any) => row.subRows,
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  const childrenRowClickable = (row) => onClickRow && row.depth === 1;

  const onClickRowHandler = (row) => {
    if (row.getCanExpand() && row.depth === 0) return row.toggleExpanded();

    if (childrenRowClickable(row)) return onClickRow(row.original.id);
  };

  return (
    <>
      <div className="w-full overflow-x-auto">
        <table className="bg-primary-100 w-full overflow-hidden rounded-2xl border-t">
          <thead className="border-b border-gray-100 bg-[#183F38]">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header, index) => (
                  <th
                    key={header.id}
                    className={`px-6 py-4 text-sm font-semibold text-gray-100 ${
                      index === 0 || index === 1 ? "text-left" : "text-center"
                    }`}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <React.Fragment key={row.id}>
                  <tr
                    key={row.id}
                    className={`${
                      row.id.includes(".")
                        ? "hover:bg-secondary-50 bg-white"
                        : "border-y border-[#D3D8DB]  bg-white hover:bg-[#EBF2F5]"
                    } cursor-pointer`}
                    {...{ onClick: () => onClickRowHandler(row) }}
                  >
                    {row.getVisibleCells().map((cell, index) => (
                      <td
                        key={cell.id}
                        className={`overflow-hidden py-6 text-sm font-semibold ${
                          index === 1
                            ? " border-[#D3D8DB] text-left text-[#183F38]"
                            : index === 0
                              ? "text-center text-[#183F38]"
                              : "text-center text-[#466560]"
                        } ${
                          row.id.includes(".")
                            ? `${index === 0 && "border-l-4 border-[#466560] pl-12"} `
                            : ""
                        }`}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Table;
