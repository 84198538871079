import { useState } from "react";
import Chart from "react-apexcharts";

import Dropdown from "@/components/Dropdown";
import Typography from "@/components/Typography";

const DividensGraphSection = () => {
  const [options]: any = useState({
    annotations: {},
    fontColor: "#818A8F",
    chart: {
      animations: {
        enabled: false,
      },
      background: "#fff",
      fontFamily: "Inter",
      height: 250,
      id: "uRSqT",
      stackOnlyBar: true,
      toolbar: {
        show: false,
      },
      type: "bar",
      width: 400,
      zoom: {
        allowMouseWheelZoom: true,
      },
    },
    plotOptions: {
      line: {
        isSlopeChart: false,
      },
      bar: {
        borderRadius: 6,
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "last",
        hideZeroBarsWhenGrouped: false,
        isDumbbell: false,
        isFunnel: false,
        isFunnel3d: true,
        colors: {
          backgroundBarColors: ["#f1f2f3"],
          backgroundBarOpacity: 0,
        },
        dataLabels: {
          total: {
            enabled: false,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: "#373d3f",
              fontSize: "12px",
              fontWeight: 600,
            },
          },
        },
      },
      bubble: {
        zScaling: true,
      },
      treemap: {
        borderRadius: 4,
        dataLabels: {
          format: "scale",
        },
      },
      radialBar: {
        hollow: {
          background: "#fff",
        },
        dataLabels: {
          // INFORMACOES A SEREM ATUALIZADAS

          name: {},
          value: {},
          total: {},
        },
        barLabels: {
          enabled: false,
          offsetX: 0,
          offsetY: 0,
          useSeriesColors: true,
          fontWeight: 600,
          fontSize: "16px",
        },
      },
      pie: {
        donut: {
          labels: {
            name: {},
            value: {},
            total: {},
          },
        },
      },
    },
    colors: ["#5EC874", "#466560", "#748C88", "#7BB4AA", "#235950"],
    dataLabels: {
      enabled: false,
      style: {
        fontWeight: 700,
      },
      background: {
        dropShadow: {},
      },
    },
    grid: {
      padding: {
        right: 25,
        left: 15,
      },
    },
    legend: {
      fontSize: 14,
      offsetY: 0,
      markers: {
        size: 7,
        strokeWidth: 4,
        shape: "line",
      },
      itemMargin: {
        vertical: 0,
      },
      labels: {
        colors: "#818A8F",
      },
    },
    markers: {},
    stroke: {
      fill: {
        type: "solid",
        opacity: 0.85,
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 100],
          colorStops: [],
        },
      },
    },
    tooltip: {
      shared: false,
      hideEmptySeries: false,
      intersect: true,
    },
    xaxis: {
      labels: {
        trim: true,
        style: {
          colors: "#818A8F",
        },
      },
      group: {
        groups: [],
        style: {
          colors: [],
          fontSize: "12px",
          fontWeight: 400,
          cssClass: "",
        },
      },
      tickPlacement: "between",
      title: {
        style: {
          fontWeight: 700,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      tickAmount: 5,
      labels: {
        showDuplicates: false,
        style: {
          colors: "#818A8F",
        },
      },
      title: {
        style: {
          fontWeight: 700,
        },
      },
    },
    theme: {
      palette: "palette2",
    },
  });

  // INFORMACOES A SEREM ATUALIZADAS

  const [series] = useState([
    {
      name: "Usina 1",
      data: [
        {
          x: "Jan",
          y: "0.1",
        },
        {
          x: "Fev",
          y: "0.2",
        },
        {
          x: "Mar",
          y: "0.8",
        },
        {
          x: "Abr",
          y: "1",
        },
        {
          x: "Mai",
          y: "0.6",
        },
        {
          x: "Jun",
          y: "0.7",
        },
        {
          x: "Jul",
          y: "0.9",
        },
        {
          x: "Ago",
          y: "0.3",
        },
        {
          x: "Set",
          y: "0.7",
        },
        {
          x: "Out",
          y: "1",
        },
        {
          x: "Nov",
          y: "0.8",
        },
        {
          x: "Dez",
          y: "0.2",
        },
      ],
      group: "apexcharts-axis-0",
      zIndex: 0,
    },
    {
      name: "Usina 2",
      group: "apexcharts-axis-0",
      data: [
        {
          x: "Jan",
          y: "1",
        },
        {
          x: "Fev",
          y: "0.9",
        },
        {
          x: "Mar",
          y: "0.7",
        },
        {
          x: "Abr",
          y: "0.4",
        },
        {
          x: "Mai",
          y: "1",
        },
        {
          x: "Jun",
          y: "0.8",
        },
        {
          x: "Jul",
          y: "0.8",
        },
        {
          x: "Ago",
          y: "0.4",
        },
        {
          x: "Set",
          y: "0.7",
        },
        {
          x: "Out",
          y: "0.6",
        },
        {
          x: "Nov",
          y: "0.2",
        },
        {
          x: "Dez",
          y: "0.4",
        },
      ],
      zIndex: 1,
    },
    {
      name: "Usina 3",
      group: "apexcharts-axis-0",
      data: [
        {
          x: "Jan",
          y: "0.4",
        },
        {
          x: "Fev",
          y: "0.5",
        },
        {
          x: "Mar",
          y: "0.6",
        },
        {
          x: "Abr",
          y: "0.2",
        },
        {
          x: "Mai",
          y: "0.1",
        },
        {
          x: "Jun",
          y: "1",
        },
        {
          x: "Jul",
          y: "0.4",
        },
        {
          x: "Ago",
          y: "0.6", // INFORMACOES A SEREM ATUALIZADAS
        },
        {
          x: "Set",
          y: "0.4",
        },
        {
          x: "Out",
          y: "0.1",
        },
        {
          x: "Nov",
          y: "0.6",
        },
        {
          x: "Dez",
          y: "0.7",
        },
      ],
      zIndex: 2,
    },
  ]);

  const years = ["2021", "2022", "2023", "2024"];

  // --------------------------

  const currentYear = new Date().getFullYear().toString();

  return (
    <section className="flex h-fit flex-col gap-5">
      <div className="flex h-fit items-center justify-between">
        <Typography variant="h4" color="black">
          Dividendos Mês a Mês
        </Typography>
        <Dropdown options={years} onSelect={() => ""} defaultValue={currentYear} />
      </div>
      <div className="h-[290px] w-full overflow-hidden rounded-3xl">
        <Chart options={options} series={series} type="bar" width="100%" height="290px" />
      </div>
    </section>
  );
};

export default DividensGraphSection;
