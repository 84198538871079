import React from "react";

import Logo from "@/components/Logo";

import Typography from "../Typography";
import { FooterDiv } from "./styles";

const Footer = () => {
  return (
    <FooterDiv>
      <div className="w-fit rounded-full bg-white px-4 py-1">
        <Logo marginClass="ml-0" />
      </div>
      <Typography variant="pLarge" color="grey500">
        © Geedee. Todos os direitos reservados.
      </Typography>
    </FooterDiv>
  );
};

export default Footer;
