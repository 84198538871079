import { PiSunHorizon } from "react-icons/pi";

import Typography from "@/components/Typography";

import { QuotasCardProps } from "./types";

const QuotasCard = ({
  plantName,
  dueNumber,
  positionValue,
  earningsValue,
  status,
}: QuotasCardProps) => {
  return (
    <div className="grid w-full grid-cols-12 rounded-3xl bg-white px-6 py-4">
      <section className="col-span-3 flex items-center gap-4 border-r border-grey200">
        <div
          className={`flex h-10 min-w-10 items-center justify-center rounded-full ${
            status == "operating" ? "bg-success300" : "bg-warning300"
          }`}
        >
          <PiSunHorizon className="text-2xl text-white" />
        </div>
        <div className="flex flex-col">
          <Typography variant="overline" color="grey500" className="uppercase">
            Usina
          </Typography>
          <Typography variant="pLarge" color="black" className="font-semibold uppercase">
            {plantName}
          </Typography>
        </div>
      </section>
      <section className="col-span-2 flex flex-col justify-center border-r border-grey200 px-8">
        <Typography variant="overline" color="grey500" className="uppercase">
          qtd de cotas
        </Typography>
        <Typography variant="pLarge" color="black" className="font-semibold uppercase">
          {dueNumber}
        </Typography>
      </section>
      <section className="col-span-2 flex flex-col border-r border-grey200 px-8">
        <Typography variant="overline" color="grey500" className="uppercase">
          posição
        </Typography>
        <Typography variant="pLarge" color="black" className="font-semibold uppercase">
          R$ {positionValue}
        </Typography>
      </section>
      <section className="col-span-3 flex flex-col justify-center border-r border-grey200 px-8">
        <Typography variant="overline" color="grey500" className="uppercase">
          rentabilidade c/ proventos
        </Typography>
        <Typography variant="pLarge" color="black" className="font-semibold uppercase">
          R$ {earningsValue}
        </Typography>
      </section>
      <section className="col-span-2 flex flex-col justify-center px-8">
        <Typography variant="overline" color="grey500" className="uppercase">
          status
        </Typography>
        <Typography
          variant="pLarge"
          color={status == "operating" ? "success300" : "warning300"}
          className="whitespace-nowrap font-semibold uppercase"
        >
          {status == "operating" ? "operando " : "em construção"}
        </Typography>
      </section>
    </div>
  );
};

export default QuotasCard;
