import styled from "styled-components";

import theme from "@/constants/theme";

export const Box = styled.div`
  min-width: 164px;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;

  .image {
    margin: 0rem 0.6rem;
    width: 30px;
    height: 30px;
    background-color: ${theme.colors.grey100};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    .icon-img {
      width: 20px;
      height: auto;
      font-weight: 700;
    }
  }

  .infos {
    padding-left: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const StatusBox = styled.div`
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  padding: 0rem 1.2rem;
`;
