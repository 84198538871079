import React from "react";
import { IoIosArrowDown } from "react-icons/io";

import { AvatarProps } from "@/components/Avatar/type";

import Typography from "../Typography";

const Avatar = ({ user }: AvatarProps) => {
  return (
    <div className="w-min-10 relative flex h-10 w-10 items-center justify-center rounded-full bg-primary300">
      <Typography variant="h6" color="white">
        {user?.charAt(0).toLocaleUpperCase()}
      </Typography>
      <div className="absolute -bottom-1 -right-1 flex h-4 w-4 cursor-pointer items-center justify-center rounded-full bg-white">
        <IoIosArrowDown className="text-black " />
      </div>
    </div>
  );
};

export default Avatar;
