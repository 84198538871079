import { useState } from "react";
import { LuWallet } from "react-icons/lu";
import { RiContactsLine } from "react-icons/ri";

import FaqCardComponent from "@/components/Cards/FaqComponent";
import TabNavBarLayout from "@/components/Layout/TabNavBarLayout";
import { NavBarItem } from "@/components/TabNavBar/types";

import DividensGraphSection from "./sections/dividendsGraphSection";
import DocsTableSection from "./sections/docsTableSection";
import QuotasSection from "./sections/quotasSection";
import SummarySection from "./sections/summarySection";

export default function InvestorIndex() {
  const [showIndex, setShowIndex] = useState(0);

  const tabs: NavBarItem[] = [
    {
      icon: LuWallet,
      label: "Investimentos",
    },
    {
      icon: RiContactsLine,
      label: "Meus dados",
    },
  ];

  switch (showIndex) {
    case 1:
      return (
        <TabNavBarLayout setShowIndex={setShowIndex} showIndex={showIndex} tabs={tabs}>
          <h1>EM BREVE...</h1>
        </TabNavBarLayout>
      );
    default:
      return (
        <TabNavBarLayout setShowIndex={setShowIndex} showIndex={showIndex} tabs={tabs}>
          <SummarySection />
          <QuotasSection />
          <DividensGraphSection />
          <DocsTableSection />
          <FaqCardComponent variant="questionSection" />
        </TabNavBarLayout>
      );
  }
}
