import SummaryCard from "@/components/Cards/Investor/ResumeCard";
import Dropdown from "@/components/Dropdown";
import Typography from "@/components/Typography";

const SummarySection = () => {
  const currentYear = new Date().getFullYear().toString();

  // INFORMACOES A SEREM ATUALIZADAS

  const years = ["2021", "2022", "2023", "2024"];

  const quotasNumber = 8;

  const fullPositionValue = "120.350,00";
  const fullPositionPercent = "3%";
  const fullPositionStatus = "up";

  const lastMonthDividendValue = "12.350,00";
  const lastMonthDividendPercent = "3%";
  const lastMonthDividendStatus = "up";
  const lastMonthDividendDate = "jan 2024";

  const dividendYieldValue = "+7.2%";
  const dividendYieldPercent = "0,2%";
  const dividendYieldStatus = "up";
  const dividendYieldDate = "ano corrente";

  // ----------------

  return (
    <section className="flex h-fit flex-col gap-5">
      <div className="flex h-fit items-center justify-between">
        <div className="flex h-full items-center gap-4">
          <Typography variant="h4" color="black">
            Resumo geral
          </Typography>
          <div className="h-6 w-px bg-grey200" />
          <Typography variant="h6" color="secondary500" className="uppercase">
            {quotasNumber} cotas adquiridas
          </Typography>
        </div>
        <Dropdown options={years} onSelect={() => ""} defaultValue={currentYear} />
      </div>
      <div className="grid grid-cols-3 gap-4">
        <SummaryCard
          value={fullPositionValue}
          percent={fullPositionPercent}
          position={fullPositionStatus}
          title="posição total"
          money={true}
        />
        <SummaryCard
          value={lastMonthDividendValue}
          percent={lastMonthDividendPercent}
          position={lastMonthDividendStatus}
          title="dividendos do último mês"
          date={lastMonthDividendDate}
          money={true}
        />
        <SummaryCard
          value={dividendYieldValue}
          percent={dividendYieldPercent}
          position={dividendYieldStatus}
          title="posição total"
          date={dividendYieldDate}
        />
      </div>
    </section>
  );
};

export default SummarySection;
