import React from "react";
import { BsPersonCircle } from "react-icons/bs";
import { TbCoins, TbPercentage, TbSunset } from "react-icons/tb";

import theme from "@/constants/theme";
import { nameFormater } from "@/utils/nameFormater";
import { distribuitionStatus, OverdueStatus } from "@/utils/statusFormater";

import Typography from "../../Typography";
import {
  Button,
  Container,
  IconDiv,
  ImageDiv,
  InfoDiv,
  Line,
  PersonInfoDiv,
  WarningDiv,
} from "./styles";
import { PersonProps } from "./types";

const PersonCard = ({
  name,
  nickname,
  situation,
  status,
  powerPlant,
  creditBalance,
  onClick,
  powerPlantPercentage,
}: PersonProps) => {
  const overdueInformation = OverdueStatus(situation);
  const distribuitionInfo = distribuitionStatus(status);

  return (
    <Container className="grid grid-flow-row-dense grid-cols-12 gap-0">
      <div className="col-span-6 grid grid-cols-2 justify-items-start">
        {nickname ? (
          <IconDiv>
            <ImageDiv>
              <BsPersonCircle color={theme.colors.black} />
            </ImageDiv>
            <PersonInfoDiv>
              <Typography variant="overline" color="grey500">
                <div className="font-bold">APELIDO</div>
              </Typography>
              <Typography variant="pLarge">{nameFormater(nickname)}</Typography>
            </PersonInfoDiv>
          </IconDiv>
        ) : (
          <IconDiv>
            <ImageDiv>
              <BsPersonCircle color={theme.colors.black} />
            </ImageDiv>
            <PersonInfoDiv>
              <Typography variant="overline" color="grey500">
                <div className="font-bold">NOME</div>
              </Typography>
              <Typography variant="pLarge">{nameFormater(name)}</Typography>
            </PersonInfoDiv>
          </IconDiv>
        )}

        {powerPlantPercentage != undefined && (
          <IconDiv>
            <ImageDiv>
              <TbPercentage color={theme.colors.black} />
            </ImageDiv>
            <PersonInfoDiv data-testid="rateio">
              <Typography variant="overline" color="grey500">
                <div className="font-bold">RATEIO</div>
              </Typography>
              <Typography variant="pLarge">{powerPlantPercentage}%</Typography>
            </PersonInfoDiv>
          </IconDiv>
        )}

        {powerPlant != undefined && powerPlant != "" ? (
          <IconDiv>
            <ImageDiv>
              <TbSunset color={theme.colors.black} />
            </ImageDiv>
            <PersonInfoDiv>
              <Typography variant="overline" color="grey500">
                <div className="font-bold">USINAS</div>
              </Typography>
              <Typography variant="pSmall">{powerPlant.toString()}</Typography>
            </PersonInfoDiv>
          </IconDiv>
        ) : (
          <IconDiv>
            <ImageDiv>
              <TbCoins color={theme.colors.black} />
            </ImageDiv>
            <PersonInfoDiv>
              <Typography variant="overline" color="grey500">
                <div className="font-bold">CRÉDITOS SOBRESSALENTES</div>
              </Typography>
              <Typography variant="pLarge">{creditBalance}</Typography>
            </PersonInfoDiv>
          </IconDiv>
        )}
      </div>

      <div className="col-span-4 flex flex-row justify-between justify-items-center">
        <Line />
        <InfoDiv>
          <Typography variant="overline" color="grey500">
            <div className="font-bold">SITUAÇÃO</div>
          </Typography>
          <WarningDiv>
            <div className={overdueInformation.backgroundColor}>
              <Typography variant="pLarge" color={overdueInformation.color}>
                {overdueInformation.label}
              </Typography>
            </div>
          </WarningDiv>
        </InfoDiv>
        <InfoDiv>
          <Typography variant="overline" color="grey500">
            <div className="font-bold">STATUS</div>
          </Typography>
          <WarningDiv>
            <div className={distribuitionInfo.backgroundColor}>
              <Typography variant="pLarge" color={distribuitionInfo.color}>
                {distribuitionInfo.label}
              </Typography>
            </div>
          </WarningDiv>
        </InfoDiv>

        <Line />
      </div>

      <div className="col-span-2 flex justify-center justify-items-end">
        <Button onClick={onClick}>Detalhes</Button>
      </div>
    </Container>
  );
};

export default PersonCard;
